<template>
  <div class="teaching" id="teaching">
    <div class="container">
      <div class="teaching__content" :class="{ active: openModal }">
        <div class="teaching__content-box">
          <img
            class="teaching__content-box-img"
            src="../assets/images/content/teach.jpg"
            alt=""
          />
          <div class="teaching__content-box-txt">
            <h6 class="teaching__content-box-txt-title">Обучаю!</h6>
            <div class="teaching__content-box-txt-line"></div>
            <p class="teaching__content-box-txt-descr">
              <span
                >Я постоянно обучаюсь и развиваюсь, и сейчас хочу поделиться
                своими знаниями с ещё большим количеством людей.</span
              ><br /><br />На моих занятиях я обучаю основным настройкам
              фотоаппарата, базовым знаниям композиции, цвета и света, показываю
              ретушь и рассказываю о продвижении фотографа в нынешних реалиях.
              <br /><br />Обязательно даю домашние задания и проверяю их, а
              также приглашаю своих учеников ассистентами на мои съемки, чтобы
              показывать как я настраиваю свет и общаюсь с моделями или
              клиентами.
            </p>
            <div class="teaching__content-box-txt-block">
              <div class="teaching__content-box-txt-block-icon">
                <a
                  class="teaching__content-box-txt-block-icon-img"
                  href="https://t.me/s/tamara_kapibara"
                  target="blank"
                  ><img src="../assets/images/icon/black/tg.png" alt=""
                /></a>
                <a
                  class="teaching__content-box-txt-block-icon-img"
                  href="https://vk.com/@tamarakapibara-moi-put-intervu-fotografa"
                  target="blank"
                  ><img src="../assets/images/icon/black/vk.png" alt=""
                /></a>
                <a
                  class="teaching__content-box-txt-block-icon-img"
                  href="https://instagram.com/tamara_kapibara?igshid=YmMyMTA2M2Y="
                  target="blank"
                  ><img src="../assets/images/icon/black/insta.png" alt=""
                /></a>
              </div>
              <span class="teaching__content-box-txt-block-bond"
                >← для связи со мной</span
              >
              <span class="teaching__content-box-txt-block-bondMob"
                >для связи со мной</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openModal: false,
    };
  },
  methods: {
    openCard() {
      this.openModal = true;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";
.teaching {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: #fff;
  position: relative;
  @include adaptivPaddinglg(0, 0, 100, 100, 0, 0, 50, 50);
  height: 100%;
  overflow: hidden;
  @media (max-width: 768px) {
    @include adaptivPaddingmd(0, 0, 100, 100, 0, 0, 50, 50);
    .container {
      width: 95%;
    }
  }
  &__content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transform: translateX(0);
    &.active {
      transition: 1s;
      transform: translateX(-100px);
      @media (max-width: 768px) {
        transform: none;
        .teaching__content-box {
          transform: translateX(-120%);
        }
      }
    }
    &-box {
      // width: 50%;
      @include adaptivWidthLg(800, 450);
      height: 100%;
      position: relative;
      box-shadow: 0px 3px 20px 0px rgb(99 99 99 / 31%);
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 2;
      &::after {
        content: "";
        background: url("../assets/images/content/teach1.png") no-repeat;
        background-size: cover;
        @include adaptivWidthLg(280, 150);
        width: 100%;
        @include adaptivHeightlg(280, 150);
        display: block;
        position: absolute;
        top: 0;
        transform: translate(50%, 70%);
        @media (max-width: 768px) {
          @include adaptivWidthmd(280, 120);
          @include adaptivHeightmd(280, 120);
          transform: translate(50%, 50%);
        }
      }
      @media (max-width: 768px) {
        max-width: 100%;
      }
      &-txt {
        @include adaptivPaddinglg(60, 60, 60, 60, 30, 30, 30, 30);
        @media (max-width: 768px) {
          @include adaptivPaddingmd(50, 50, 30, 40, 15, 15, 20, 30);
        }
        &-title {
          color: #701d1c;
          font-family: "Playfair Display", serif;
          font-weight: 700;
          @include adaptiv-fontlg(40, 25);
          @include adaptiv-lineHeightlg(53, 38);
          text-transform: uppercase;
          @media (max-width: 768px) {
            @include adaptiv-fontmd(50, 25);
            @include adaptiv-lineHeightmd(53, 38);
          }
        }
        &-line {
          width: 30%;
          height: 3px;
          background: #701d1c;
          @include adaptivMarginlg(0, 0, 15, 35, 0, 0, 10, 20);
          display: flex;
          align-items: center;
          @media (max-width: 768px) {
            width: 50%;
            height: 2px;
            @include adaptivMarginmd(0, 0, 25, 25, 0, 0, 13, 13);
          }
        }
        &-descr {
          width: 90%;
          color: #181818;
          font-family: "Helvetica-normal";
          @include adaptiv-fontlg(18, 13);
          @include adaptiv-lineHeightlg(30, 17);
          @media (max-width: 768px) {
            width: 100%;
            @include adaptiv-fontmd(26, 13);
            @include adaptiv-lineHeightmd(38, 18);
          }
          span {
            font-family: "Helvetica-bold";
            @include adaptiv-fontlg(20, 13);
            @include adaptiv-lineHeightlg(26, 17);
            @media (max-width: 768px) {
              @include adaptiv-fontmd(29, 14);
              @include adaptiv-lineHeightmd(38, 20);
            }
          }
        }
        &-block {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include adaptivMarginlg(0, 0, 40, 0, 0, 0, 20, 0);
          @media (max-width: 768px) {
            flex-direction: column-reverse;
            align-items: flex-start;
          }
          &-icon {
            width: 65%;
            display: flex;
            @media (max-width: 768px) {
              margin-top: auto;
              width: 100%;
              justify-content: flex-start;
              align-items: center;
            }
            &-img {
              @include adaptivWidthLg(84, 42);
              width: 100%;
              @include adaptivHeightlg(84, 42);
              border-radius: 50%;
              border: 1px solid #3c3c3c25;
              display: flex;
              align-items: center;
              justify-content: center;
              @include adaptivMarginlg(0, 40, 0, 0, 0, 20, 0, 0);
              transition: 0.5s;
              @media (max-width: 768px) {
                @include adaptivWidthmd(60, 50);
                @include adaptivHeightmd(60, 50);
                @include adaptivMarginmd(0, 40, 0, 0, 0, 20, 0, 0);
              }
              img {
                @include adaptivWidthLg(26, 16);
                @media (max-width: 768px) {
                  @include adaptivWidthmd(20, 15);
                }
              }
              &:hover {
                border: 1px solid #3c3c3c;
                transition: 0.5s;
              }
            }
          }
          &-bond {
            @include adaptiv-fontlg(18, 13);
            @include adaptiv-lineHeightlg(30, 17);
            font-family: "Helvetica-normal";
            color: #181818;
            @media (max-width: 768px) {
              display: none;
            }
          }
          &-bondMob {
            display: none;
            @media (max-width: 768px) {
              display: block;
              @include adaptiv-fontmd(26, 13);
              @include adaptiv-lineHeightmd(38, 18);
              font-family: "Helvetica-normal";
              color: #181818;
              text-align: left;
              @include adaptivMarginmd(0, 0, 0, 30, 0, 0, 0, 15);
            }
          }
        }
      }
      &-img {
        width: 100%;
        @include adaptivHeightlg(350, 200);
        object-fit: cover;
        @media (max-width: 768px) {
          height: 100%;
        }
      }
      // &-descr {
      //   width: 80%;
      //   color: #252525;
      //   font-family: "Helvetica-normal";
      //   @include adaptiv-fontlg(20, 14);
      //   @include adaptiv-lineHeightlg(22, 17);
      //   text-align: center;
      //   @include adaptivMarginlg(0, 0, 150, 30, 0, 0, 80, 15);
      //   @media (max-width: 768px) {
      //     width: 90%;
      //     @include adaptiv-fontmd(20, 14);
      //     @include adaptiv-lineHeightmd(22, 17);
      //     @include adaptivMarginmd(0, 0, 180, 30, 0, 0, 70, 15);
      //   }
      // }
      // &-title {
      //   color: #23003e;
      //   font-family: "Playfair Display", serif;
      //   font-weight: 700;
      //   @include adaptiv-fontlg(40, 25);
      //   @include adaptiv-lineHeightlg(53, 38);
      //   @media (max-width: 768px) {
      //     @include adaptiv-fontmd(40, 25);
      //     @include adaptiv-lineHeightmd(53, 38);
      //   }
      // }
      // &-arrow {
      //   @include adaptivWidthLg(112, 100);
      //   width: 100%;
      //   @include adaptivHeightlg(23, 20);
      //   @include adaptivMarginlg(0, 0, 30, 30, 0, 0, 15, 15);
      //   @media (max-width: 768px) {
      //     @include adaptivWidthmd(112, 80);
      //     @include adaptivHeightmd(23, 15);
      //   }
      // }
      // &-btn {
      //   @include adaptivWidthLg(300, 150);
      //   width: 100%;
      //   background: #701d1c;
      //   @include adaptivPaddinglg(0, 0, 15, 15, 0, 0, 8, 8);
      //   color: #fff;
      //   font-family: "Helvetica-bold";
      //   @include adaptiv-fontlg(18, 11);
      //   @include adaptiv-lineHeightlg(18, 11);
      //   &:hover {
      //     background: #294639;
      //     color: #fff;
      //     transition: 0.5s;
      //   }
      //   @media (max-width: 768px) {
      //     @include adaptivWidthmd(300, 150);
      //     @include adaptivPaddingmd(0, 0, 15, 15, 0, 0, 8, 8);
      //     @include adaptiv-fontmd(18, 11);
      //     @include adaptiv-lineHeightmd(18, 11);
      //   }
      // }
    }
    // &-form {
    //   width: 35%;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: center;
    //   position: absolute;
    //   z-index: 1;
    //   transform: translateX(0);
    //   &.active {
    //     position: relative;
    //     transition: 1s;
    //     transform: translateX(150px);
    //     @media (max-width: 768px) {
    //       transform: translateX(0px);
    //       width: 100%;
    //       position: absolute;
    //       transition: 0.8s;
    //       opacity: 1;
    //     }
    //   }
    //   @media (max-width: 768px) {
    //     width: 100%;
    //     transform: translateX(50px);
    //     transition: 0.8s;
    //     opacity: 0;
    //   }
    //   &-txt {
    //     display: flex;
    //     align-items: center;
    //     img {
    //       @include adaptivWidthLg(70, 40);
    //       @include adaptivHeightlg(70, 40);
    //       border: 3px solid orange;
    //       border-radius: 50%;
    //       @media (max-width: 768px) {
    //         @include adaptivWidthmd(70, 40);
    //         @include adaptivHeightmd(70, 40);
    //       }
    //     }
    //     &-name {
    //       display: flex;
    //       flex-direction: column;
    //       @include adaptivMarginlg(16, 0, 0, 0, 8, 0, 0, 0);
    //       &-title {
    //         @include adaptiv-fontlg(16, 13);
    //         @include adaptiv-lineHeightlg(21, 18);
    //         font-family: "Playfair Display", serif;
    //         font-weight: 400;
    //         color: #6a14ad;
    //         @media (max-width: 768px) {
    //           @include adaptiv-fontmd(16, 13);
    //           @include adaptiv-lineHeightmd(21, 18);
    //         }
    //       }
    //       &-descr {
    //         @include adaptiv-fontlg(12, 9);
    //         @include adaptiv-lineHeightlg(15, 12);
    //         font-family: "Helvetica-normal";
    //         color: #252525;
    //         @media (max-width: 768px) {
    //           @include adaptiv-fontmd(12, 9);
    //           @include adaptiv-lineHeightmd(15, 12);
    //         }
    //       }
    //     }
    //   }
    //   &-line {
    //     width: 100%;
    //     height: 1px;
    //     background: #cccccc;
    //     @include adaptivMarginlg(0, 0, 25, 80, 0, 0, 13, 40);
    //     display: flex;
    //     align-items: center;
    //     @media (max-width: 768px) {
    //       width: 100%;
    //       @include adaptivMarginmd(0, 0, 25, 25, 0, 0, 13, 13);
    //     }
    //     &::before {
    //       content: "";
    //       width: 3px;
    //       height: 3px;
    //       border-radius: 50%;
    //       background: #cccccc;
    //       display: block;
    //     }
    //     &::after {
    //       content: "";
    //       width: 3px;
    //       height: 3px;
    //       border-radius: 50%;
    //       background: #cccccc;
    //       display: block;
    //       margin-left: auto;
    //     }
    //   }
    //   &-contact {
    //     &-span {
    //       @include adaptiv-fontlg(48, 30);
    //       @include adaptiv-lineHeightlg(56, 38);
    //       font-family: "Playfair Display", serif;
    //       font-weight: 900;
    //       color: #000201;
    //       @media (max-width: 768px) {
    //         @include adaptiv-fontmd(48, 30);
    //         @include adaptiv-lineHeightmd(56, 38);
    //       }
    //     }
    //     &-descr {
    //       @include adaptiv-fontlg(16, 13);
    //       @include adaptiv-lineHeightlg(23, 20);
    //       font-family: "Helvetica-normal";
    //       color: #252525;
    //       @include adaptivMarginlg(0, 0, 15, 50, 0, 0, 10, 25);
    //       @media (max-width: 768px) {
    //         @include adaptiv-fontmd(16, 13);
    //         @include adaptiv-lineHeightmd(23, 20);
    //       }
    //     }
    //   }
    //   .form {
    //     width: 100%;
    //     display: flex;
    //     flex-direction: column;
    //     &-input {
    //       @include adaptivHeightlg(100, 60);
    //       border-bottom: 1px solid #cccccc;
    //       color: #343434;
    //       font-weight: 600;
    //       font-family: "Inter", sans-serif;
    //       @include adaptiv-fontlg(12, 10);
    //       @include adaptiv-lineHeightlg(14, 12);
    //       border-radius: 0px;
    //       &::placeholder {
    //         @include adaptiv-fontlg(10, 8);
    //         @include adaptiv-lineHeightlg(10, 8);
    //         font-weight: 400;
    //         color: #cccccc;
    //         @media (max-width: 768px) {
    //           @include adaptiv-fontmd(10, 8);
    //           @include adaptiv-lineHeightmd(10, 8);
    //         }
    //       }
    //     }
    //     .textarea {
    //       padding: 40px 0 0 0;
    //       @include adaptivPaddinglg(0, 0, 40, 0, 0, 0, 25, 0);
    //     }
    //     &-btn {
    //       background: #701d1c;
    //       @include adaptivPaddinglg(0, 0, 20, 20, 0, 0, 10, 10);
    //       color: #fff;
    //       font-family: "Helvetica-bold";
    //       @include adaptiv-fontlg(13, 10);
    //       @include adaptiv-lineHeightlg(13, 10);
    //       text-transform: uppercase;
    //       @include adaptivMarginlg(0, 0, 80, 0, 0, 0, 40, 0);
    //       transition: 0.5s;
    //       &:hover {
    //         background: #294639;
    //         color: #fff;
    //         transition: 0.5s;
    //       }
    //       @media (max-width: 768px) {
    //         @include adaptiv-fontmd(13, 10);
    //         @include adaptiv-lineHeightmd(13, 10);
    //         @include adaptivPaddingmd(0, 0, 20, 20, 0, 0, 10, 10);
    //         @include adaptivMarginmd(0, 0, 80, 0, 0, 0, 40, 0);
    //       }
    //     }
    //   }
    // }
  }
}
</style>