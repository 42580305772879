<template>
  <div class="loveStory" id="loveStory" ref="loveStory">
    <div class="loveStory-head">
      <div class="container">
        <h4 class="loveStory-head-title">wedding</h4>
        <div class="loveStory-head-line"></div>
        <p class="loveStory-head-descr">Гораздо больше, чем просто история</p>
      </div>
    </div>
    <swiper :slidesPerView="1" :navigation="true" :breakpoints="{
      '320': {
        slidesPerView: 1,
      },
      '500': {
        slidesPerView: 2,
      },
      '600': {
        slidesPerView: 2,
      },
      '768': {
        slidesPerView: 2,
      },
      '1024': {
        slidesPerView: 3,
      },
      '1920': {
        slidesPerView: 4,
      },
    }" :loop="true" :autoplay="{
  delay: 3000,
  disableOnInteraction: false,
}" :modules="modules" class="loveStorySlider">
      <swiper-slide class="loveStorySlider__slide" v-for="item in getWeddingGallery" :key="item.id">
        <router-link :to="{
          name: 'photoshoots',
          params: { id: item.slug },
        
        }" class="loveStorySlider__slide-link">
          <div class="loveStorySlider__slide-img">
            <div class="wrap">
              <span class="showTxt">смотреть</span>
              <img :src="item.image" :alt="item.alt" class="loveStorySlider__slide-img-round" />
            </div>

          </div>
          <div class="loveStorySlider__slide-name">
            <h5 class="loveStorySlider__slide-name-title">{{ item.title }}</h5>
            <p class="loveStorySlider__slide-name-descr" v-if="item.subtitle_slide">{{ item.subtitle_slide }}</p>
          </div>
        </router-link>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
    };
  },
  // props: {
  //   item: Object,
  // },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation, Autoplay],
    };
  },
  methods: {
  },
  computed: {
    pageData() {
      return this.$store.getters.getPageData(this.$route.params.id);
    },
    ...mapGetters({
      getWeddingGallery: 'getWeddingGallery'
    })
  },
  mounted() {
    if (this.$route.hash == "#wedding") {
      window.location.hash = "";
      window.scrollTo(0, this.$refs.loveStory.offsetTop);
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";

.loveStory {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background: #fff;
  @include adaptivPaddinglg(0, 0, 60, 50, 0, 0, 30, 25);

  @media (max-width: 768px) {
    @include adaptivPaddingmd(0, 0, 60, 60, 0, 0, 40, 40);
  }

  &-head {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-title {
      @include adaptiv-fontlg(80, 40);
      @include adaptiv-lineHeightlg(80, 40);
      font-family: "Playfair Display", serif;
      font-weight: 400;
      color: #5c001f;

      @media (max-width: 768px) {
        @include adaptiv-fontmd(80, 40);
        @include adaptiv-lineHeightmd(80, 40);
      }
    }

    &-line {
      width: 70%;
      height: 1px;
      background: #5c001f;
      @include adaptivMarginlg(0, 0, 25, 25, 0, 0, 13, 13);
      display: flex;
      align-items: center;

      @media (max-width: 768px) {
        width: 70%;
        @include adaptivMarginmd(0, 0, 25, 25, 0, 0, 13, 13);
      }

      &::before {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #5c001f;
        display: block;
      }

      &::after {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #5c001f;
        display: block;
        margin-left: auto;
      }
    }

    &-descr {
      @include adaptiv-fontlg(25, 13);
      @include adaptiv-lineHeightlg(25, 13);
      font-family: "Helvetica-normal";
      color: #5a5a5a;

      @media (max-width: 768px) {
        @include adaptiv-fontmd(25, 13);
        @include adaptiv-lineHeightmd(25, 13);
      }
    }
  }

  .loveStorySlider {
    width: 80%;
    @include adaptivMarginlg(0, 0, 50, 0, 0, 0, 25, 0);
    position: static;

    @media (max-width: 768px) {
      overflow: visible;
      @include adaptivMarginmd(0, 0, 50, 0, 0, 0, 25, 0);
    }

    &__slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      @include adaptivPaddinglg(40, 40, 0, 0, 20, 20, 0, 0);

      @media (max-width: 768px) {
        @include adaptivPaddingmd(20, 20, 0, 0, 10, 10, 0, 0);
      }

      &-link {
        display: block;
        width: 100%;

      }

      &-img {

        transition: 0.5s;
        width: 100%;
        // @include adaptivWidthLg(300, 250);
        // @include adaptivHeightlg(300, 250);
        filter: brightness(1);
        overflow: hidden;

        .wrap {
          position: relative;
          padding-top: 100%;
        }

        &-round {
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .showTxt {
          width: 100%;
          text-align: center;
          position: absolute;
          color: #fff;
          top: 50%;
          left: 50%;
          z-index: 1;
          transform: translate(-50%, -50%);
          @include adaptiv-fontlg(15, 13);
          @include adaptiv-lineHeightlg(22, 16);
          font-family: "Roboto", sans-serif;
          font-weight: 300;
          opacity: 0;
          transition: 0.5s;

          @media (max-width: 768px) {
            @include adaptiv-fontmd(15, 13);
            @include adaptiv-lineHeightmd(22, 16);
          }
        }
      }

      &:hover {
        .loveStorySlider__slide-img {
          &-round {

            filter: brightness(0.6);
            transition: 0.5s;
          }
        }

        .showTxt {
          opacity: 1;
          transition: 0.5s;
        }

        .loveStorySlider__slide-name-title,
        .loveStorySlider__slide-name-descr {
          color: #5c001f;
          transition: 0.5s;
        }
      }

      &-name {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include adaptivMarginlg(0, 0, 25, 0, 0, 0, 13, 0);

        &-title {
          @include adaptiv-fontlg(21, 15);
          @include adaptiv-lineHeightlg(25, 19);
          font-family: "Roboto", sans-serif;
          font-weight: 700;
          color: #202e28;

          @media (max-width: 768px) {
            @include adaptiv-fontmd(18, 15);
            @include adaptiv-lineHeightmd(25, 19);
          }
        }

        &-descr {
          @include adaptiv-fontlg(15, 13);
          @include adaptiv-lineHeightlg(22, 16);
          font-family: "Roboto", sans-serif;
          font-weight: 300;
          color: #202e28;

          @media (max-width: 768px) {
            @include adaptiv-fontmd(15, 13);
            @include adaptiv-lineHeightmd(22, 16);
          }
        }
      }
    }

    .swiper-wrapper {
      display: flex;
      // align-items: center;
    }

    .swiper-button-prev {
      display: none;
    }

    .swiper-button-next {
      position: absolute;
      z-index: 10;
      @include adaptivWidthLg(80, 50);
      width: 100%;
      @include adaptivHeightlg(80, 50);
      transform: translate(-180%, 50%);

      @media (max-width: 768px) {
        display: none;
      }

      &::after {
        background-image: url("../assets/images/icon/arrowRight.png");
        background-size: cover;
        display: block;
        width: 100%;
        height: 100%;
        content: "";
      }
    }
  }
}
</style>