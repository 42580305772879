<template>
  <div class="about" id="about">
    <div class="about__content">
      <div class="about__content-txt">
        <span class="about__content-txt-span">обо мне</span>
        <h1 class="about__content-txt-title">Tamara Kapibara</h1>
        <p class="about__content-txt-descr">
          Привет! Уже более 6 лет я фотографирую и делюсь своими кадрами с вами.
          Моей целью на съемке становится создание атмосферы или эстетики вашего
          любимого фильма или произведения.<br /><br /><span
            >Но я против слепого копирования, а лишь вдохновляюсь произведениями
            и создаю что-то своё.</span
          >
          <br /><br />За последние 4 года я активно начала развивать свадебную
          сферу и создавать атмосферные и эстетичные кадры для молодожён.<br /><span
            >Я буду рада, если смогу запечатлеть важный момент в вашей
            жизни.</span
          >
        </p>
        <div class="line"></div>
        <div class="about__content-txt-block">
          <div class="about__content-txt-block-icon">
            <a
              class="about__content-txt-block-icon-img"
              href="https://t.me/s/tamara_kapibara"
              target="blank"
              ><img src="../assets/images/icon/white/tg.png" alt=""
            /></a>
            <a
              class="about__content-txt-block-icon-img"
              href="https://vk.com/@tamarakapibara-moi-put-intervu-fotografa"
              target="blank"
              ><img src="../assets/images/icon/white/vk.png" alt=""
            /></a>
            <a
              class="about__content-txt-block-icon-img"
              href="https://instagram.com/tamara_kapibara?igshid=YmMyMTA2M2Y="
              target="blank"
              ><img src="../assets/images/icon/white/insta.png" alt=""
            /></a>
          </div>
          <span class="about__content-txt-block-bond">← для связи со мной</span>
          <span class="about__content-txt-block-bondMob"
            >для связи со мной</span
          >
        </div>
      </div>
      <div class="about__content-img">
        <img src="../assets/images/content/photo.webp" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";
.about {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: #701d1c;
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    height: 100%;
  }
  &__content {
    width: 100%;
    @include adaptivHeightlg(950, 550);
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
      position: relative;
      height: 100%;
    }
    &-txt {
      width: 60%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      @include adaptivPaddinglg(130, 100, 60, 40, 65, 50, 30, 20);
      @media (max-width: 768px) {
        width: 100%;
        @include adaptivPaddingmd(50, 50, 30, 30, 25, 25, 20, 20);
      }
      &-span {
        @include adaptiv-fontlg(20, 10);
        @include adaptiv-lineHeightlg(20, 10);
        font-family: "Playfair Display", serif;
        font-weight: 400;
        color: #fff;
        @media (max-width: 768px) {
          position: absolute;
          top: 1%;
          @include adaptiv-fontmd(22, 12);
          @include adaptiv-lineHeightmd(25, 15);
        }
      }
      &-title {
        @include adaptiv-fontlg(63, 35);
        @include adaptiv-lineHeightlg(85, 58);
        font-family: "Playfair Display", serif;
        font-weight: 700;
        color: #fff;
        @include adaptivMarginlg(0, 0, 60, 0, 0, 0, 30, 0);
        @media (max-width: 768px) {
          @include adaptiv-fontmd(100, 40);
          @include adaptiv-lineHeightmd(120, 50);
          margin-top: 0;
          position: absolute;
          transform: translate(0%, -135%);
          background: rgb(0 0 0 / 2%);
        }
      }
      &-descr {
        @include adaptiv-fontlg(25, 16);
        @include adaptiv-lineHeightlg(38, 23);
        font-family: "Helvetica-thin";
        color: #fff;
        @include adaptivMarginlg(0, 0, 60, 0, 0, 0, 30, 0);
        @media (max-width: 768px) {
          @include adaptiv-fontmd(25, 15);
          @include adaptiv-lineHeightmd(38, 24);
          margin-top: 0;
        }
        span {
          font-family: "Helvetica-medium";
        }
      }
      .line {
        width: 40%;
        height: 2px;
        background: #fff;
        margin-top: 30px;
        @include adaptivMarginlg(0, 0, 30, 0, 0, 0, 15, 0);
      }
      &-block {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include adaptivMarginlg(0, 0, 80, 0, 0, 0, 30, 0);
        @media (max-width: 768px) {
          flex-direction: column-reverse;
          align-items: flex-start;
        }
        &-icon {
          width: 65%;
          display: flex;
          @media (max-width: 768px) {
            margin-top: auto;
            width: 100%;
            justify-content: flex-start;
            align-items: center;
          }
          &-img {
            @include adaptivWidthLg(84, 42);
            width: 100%;
            @include adaptivHeightlg(84, 42);
            border-radius: 50%;
            border: 1px solid rgb(255 255 255 / 12%);
            display: flex;
            align-items: center;
            justify-content: center;
            @include adaptivMarginlg(0, 40, 0, 0, 0, 20, 0, 0);
            transition: 0.5s;
            @media (max-width: 768px) {
              @include adaptivWidthmd(60, 50);
              @include adaptivHeightmd(60, 50);
              @include adaptivMarginmd(0, 40, 0, 0, 0, 20, 0, 0);
            }
            img {
              @include adaptivWidthLg(22, 12);
              @media (max-width: 768px) {
                @include adaptivWidthmd(20, 15);
              }
            }
            &:hover {
              border: 1px solid #fff;
              transition: 0.5s;
            }
          }
        }
        &-bond {
          @include adaptiv-fontlg(20, 12);
          @include adaptiv-lineHeightlg(26, 18);
          font-family: "Helvetica-thin";
          color: #fff;
          @media (max-width: 768px) {
            display: none;
          }
        }
        &-bondMob {
          display: none;
          @media (max-width: 768px) {
            display: block;
            @include adaptiv-fontmd(20, 12);
            @include adaptiv-lineHeightmd(26, 18);
            font-family: "Helvetica-thin";
            color: #fff;
            text-align: left;
            @include adaptivMarginmd(0, 0, 0, 30, 0, 0, 0, 15);
          }
        }
      }
    }
    &-img {
      width: 40%;
      height: 100%;
      object-fit: cover;
      @media (max-width: 768px) {
        width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>