import Api from "@/api"

export default {
  state: {
    content: {
      header_slider: [],
      slider: [],
      collage: [],
      gallery: {
        lwedding: [],
        photo_gallery: [],
      },
      price:[],
      review:[]
    },
    gallery:{}
  },
  mutations: {
    setContent(state, data){
      state.content = data
    },
    setGallery(state, {slug, data}){
      state.gallery[slug] = data
    }
  },
  actions: {
    async getContent(context){
      const response = await Api.getContent()
      if(response.status == 200){
        context.commit('setContent', response.data)
      }
    },
    async getGallery(context, slug){
      const response = await Api.getGallery(slug)
      if(response.status == 200){
        context.commit('setGallery', {slug:slug, data:response.data})
      }
      return response.status
    },
    async existsGallery(context, slug){
      let status = false;
      if(Object.keys(context.state.gallery).indexOf(slug) != -1){
        status = true
      }
      if(!status){
        const result = await context.dispatch('getGallery', slug)
        if (result == 200){
          status = true
        }
      }
      return status
    },

  },
  getters:{
    getGalleryContent: (state) => (slug) =>{
      return state.gallery[slug]
    },
    getHeaderSlider(state){
      return state.content.header_slider
    },
    getSlider(state){
      return state.content.slider
    },
    getCollage(state){
      return state.content.collage
    },
    getWeddingGallery(state){
      return state.content.gallery.wedding
    },
    getPhotoGallery(state){
      return state.content.gallery.photo_gallery
    },
    getPrice(state){
      return state.content.price
    },
    getReview(state){
      return state.content.review
    }
  },
  modules: {
  }
}