import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import "@/assets/scss/main.scss";
import FontAwesomeIcon from "@/fontawesome-icons";

createApp(App)
.use(store)
.use(router)
.component('font-awesome-icon', FontAwesomeIcon)
.mount("#app-kapibara");
