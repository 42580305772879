<template>
  <div class="price" id="price">
    <h2 class="price-title">price</h2>
    <div class="price-title-line"></div>
    <div class="price__content">
      <div class="price__content-absolute">
        <div class="price__content-cards" v-for="item in getPrice" :key="item.id">
          <img class="price__content-cards-img" :src="item.image" :alt="item.alt" />
          <h6 class="price__content-cards-title">
            {{ item.price }}<span v-if="item.price_note">{{ item.price_note }}</span>
          </h6>
          <div class="price__content-cards-descr">
            <p v-html="item.title"></p>
            <span v-if="item.subtitle">{{ item.subtitle }}</span></div>
          <div class="price__content-cards-points">
            <div class="price__content-cards-points-item" v-for="service in item.price_service_list" :key="service.id">
              <font-awesome-icon class="icon" icon="fa-solid fa-check" v-if="service.text_type == 'check'" />
              <font-awesome-icon class="icon-star" icon="fa-solid fa-star-of-life" v-if="service.text_type == 'star'" />
              <p v-html="service.text" :class="{bold: service.text_type == 'star'}"></p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      getPrice: "getPrice"
    })
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";
.price {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include adaptivPaddinglg(0, 0, 100, 100, 0, 0, 50, 50);
  @include adaptivHeightlg(1100, 700);
  @media (max-width: 768px) {
    @include adaptivPaddingmd(0, 0, 50, 0, 0, 0, 20, 0);
    height: 100%;
  }
  &-title {
    @include adaptiv-fontlg(80, 40);
    @include adaptiv-lineHeightlg(80, 40);
    font-family: "Playfair Display", serif;
    font-weight: 400;
    color: #5c001f;
    text-align: center;
    @media (max-width: 768px) {
      @include adaptiv-fontmd(80, 40);
      @include adaptiv-lineHeightmd(80, 40);
    }
    &-line {
      width: 60%;
      height: 1px;
      background: #5c001f;
      @include adaptivMarginlg(0, 0, 25, 100, 0, 0, 13, 70);
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        width: 90%;
        @include adaptivMarginmd(0, 0, 25, 40, 0, 0, 13, 20);
      }
      &::before {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #5c001f;
        display: block;
      }
      &::after {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #5c001f;
        display: block;
        margin-left: auto;
      }
    }
  }
  &__content {
    background: #294639;
    width: 100%;
    @include adaptivHeightlg(500, 300);
    position: relative;
    @media (max-width: 768px) {
      height: 100%;
      @include adaptivPaddingmd(0, 0, 20, 40, 0, 0, 20, 20);
    }
    &-absolute {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      position: absolute;
      @media (max-width: 768px) {
        flex-direction: column;
        height: 100%;
        align-items: center;
        position: static;
      }
    }
    &-cards {
      background: #fff;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include adaptivPaddinglg(40, 40, 50, 40, 20, 20, 20, 20);
      @include adaptivWidthLg(480, 260);
      width: 100%;
      min-height: 100%;
      overflow: hidden;
      @include adaptivMarginlg(0, 0, 80, 0, 0, 0, 60, 0);
      box-shadow: 0px 3px 20px 0px rgb(99 99 99 / 31%);
      transform: translateY(0px);
      transition: 0.5s;
      cursor: pointer;
      &:hover {
        background: #701d1c;
        transform: translateY(-100px);
        transition: 0.5s;
        @media (max-width: 768px) {
          transform: none;
        }
        .price__content-cards-title,
        .price__content-cards-title span,
        .price__content-cards-descr,
        .price__content-cards-points-item,
        .price__content-cards-info,
        .price__content-cards-span,
        .icon-star,
        .bold {
          color: #fff;
          transition: 0.5s;
        }
        .price__content-cards-descr {
          &::before,
          &::after {
            background: #fff;
          }
        }
      }
      @media (max-width: 768px) {
        @include adaptivWidthmd(450, 290);
        @include adaptivMarginmd(0, 0, 20, 20, 0, 0, 15, 15);
        @include adaptivPaddingmd(45, 45, 45, 45, 22, 22, 25, 25);
      }
      .icon-star {
        @include adaptiv-fontlg(8, 5);
        @include adaptiv-lineHeightlg(8, 5);
        @include adaptivMarginlg(0, 15, 0, 0, 0, 7, 0, 0);
        color: #701d1c;
        @media (max-width: 768px) {
          @include adaptivMarginmd(0, 12, 0, 0, 0, 7, 0, 0);
          @include adaptiv-fontmd(8, 5);
          @include adaptiv-lineHeightmd(8, 5);
        }
      }
      &-info {
        color: #701d1c;
        font-family: "Helvetica-medium";
      }
      &-img {
        @include adaptivWidthLg(80, 40);
        @media (max-width: 768px) {
          @include adaptivWidthmd(80, 50);
        }
      }
      &-title {
        @include adaptiv-fontlg(46, 30);
        @include adaptiv-lineHeightlg(46, 30);
        font-family: "Nunito", sans-serif;
        font-weight: 500;
        color: #181818;
        text-align: center;
        transition: 0.5s;
        @include adaptivMarginlg(0, 0, 10, 0, 0, 0, 5, 0);
        @media (max-width: 768px) {
          @include adaptiv-fontmd(58, 40);
          @include adaptiv-lineHeightmd(48, 33);
          @include adaptivMarginmd(0, 0, 20, 0, 0, 0, 10, 0);
        }
        span {
          @include adaptiv-fontlg(15, 10);
          @include adaptiv-lineHeightlg(25, 20);
          font-family: "Helvetica-normal";
          color: #878787;
          @media (max-width: 768px) {
            @include adaptiv-fontmd(15, 12);
            @include adaptiv-lineHeightmd(15, 12);
          }
        }
      }
      &-span {
        @include adaptiv-fontlg(15, 10);
        @include adaptiv-lineHeightlg(15, 10);
        font-family: "Helvetica-normal";
        color: #878787;
        @media (max-width: 768px) {
          @include adaptiv-fontmd(15, 12);
          @include adaptiv-lineHeightmd(25, 22);
        }
      }
      &-descr {
        width: 100%;
        @include adaptiv-fontlg(16, 12);
        @include adaptiv-lineHeightlg(21, 17);
        font-family: "Helvetica-roman";
        color: #701d1c;
        text-align: center;
        text-transform: uppercase;
        @include adaptivMarginlg(0, 0, 30, 30, 0, 0, 15, 15);
        @include adaptivHeightlg(50, 30);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
        &:before,
        &:after {
          content: "";
          @include adaptivWidthLg(70, 35);
          width: 100%;
          height: 2px;
          background: #5c001f;
          display: block;
          position: absolute;
        }
        &:before {
          left: 10%;
        }
        &:after {
          right: 10%;
        }
        span {
          @include adaptiv-fontlg(12, 8);
          @include adaptiv-lineHeightlg(14, 10);
          text-transform: lowercase;
          @media (max-width: 768px) {
            @include adaptiv-fontmd(12, 10);
            @include adaptiv-lineHeightmd(14, 10);
          }
        }
        @media (max-width: 768px) {
          @include adaptiv-fontmd(16, 13);
          @include adaptiv-lineHeightmd(21, 17);
          @include adaptivMarginmd(0, 0, 30, 30, 0, 0, 20, 20);
        }
      }
      &-points {
        width: 100%;
        display: flex;
        flex-direction: column;
        &-item {
          @include adaptiv-fontlg(15, 10);
          @include adaptiv-lineHeightlg(22, 13);
          font-family: "Helvetica-roman";
          color: #000;
          @include adaptivMarginlg(0, 0, 11, 11, 0, 0, 6, 6);
          transition: 0.5s;
          display: flex;
          @media (max-width: 768px) {
            @include adaptiv-fontmd(15, 12);
            @include adaptiv-lineHeightmd(22, 17);
            @include adaptivMarginmd(0, 0, 11, 11, 0, 0, 6, 6);
          }
          span {
            font-family: "Helvetica-bold";
          }
          .bold{
            font-family: "Helvetica-bold";
            color: #701d1c;
          }
          .icon {
            @include adaptiv-fontlg(17, 13);
            @include adaptiv-lineHeightlg(17, 13);
            @include adaptivMarginlg(0, 15, 0, 0, 0, 7, 0, 0);
            @media (max-width: 768px) {
              @include adaptiv-fontmd(17, 13);
              @include adaptiv-lineHeightmd(17, 13);
              @include adaptivMarginmd(0, 12, 0, 0, 0, 7, 0, 0);
            }
          }
        }
      }
    }
  }
}
</style>