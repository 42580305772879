import axios from "axios";

class Api{
    constructor(){
        this.client = axios.create({
            baseURL: "/api/",
          });
    }

    async getContent(){
        let response

        try {
            response = await this.client.get('get_content')
        } catch (error) {
            response = error.response
        }
        return response
    }
    async getGallery(slug){
        let response

        try {
            response = await this.client.get('get_gallery/' + slug)
        } catch (error) {
            response = error.response
        }
        return response
    }
    async sendForm(data){
        let response

        try {
            response = await this.client.post('send_form', data)
        } catch (error) {
            response = error.response
        }
        return response
    }
}

export default new Api()
