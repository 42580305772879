<template>
  <div class="reviews" id="reviews">
    <h3 class="reviews-title">Отзывы</h3>
    <swiper :navigation="{
      nextEl: '.reviewsSlider__navigation-next',
      prevEl: '.reviewsSlider__navigation-prev',
    }" :breakpoints="{
  '769': {
    slidesPerView: 3,
  }
}" :loop="true" :modules="modules" class="reviewsSlider">
      <swiper-slide class="reviewsSlider__slide" v-for="item in getReview" :key="item.id">
        <div class="reviewsSlider__slide-content">
          <img class="reviewsSlider__slide-img" :src="item.image" :alt="item.alt" />
          <div class="reviewsSlider__slide-name">
            <h5 class="reviewsSlider__slide-name-title">{{ item.title }}</h5>
            <div class="reviewsSlider__slide-name-link">
              <a :href="item.link" target="blank" v-html="item.link"></a>
              <a :href="item.link_second" target="blank" v-html="item.link_second" v-if="item.link_second"></a>
            </div>
            <p class="reviewsSlider__slide-name-descr" v-html="item.text"></p>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="container">
      <div class="reviewsSlider__navigation">
        <div class="reviewsSlider__navigation-prev"></div>
        <div class="reviewsSlider__navigation-next"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapGetters({
      getReview: "getReview"
    })
  },
  setup() {
    return {
      modules: [Navigation, Autoplay,],
    };
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/grid/grid";

.reviews {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: #701d1c;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  @include adaptivPaddinglg(0, 0, 45, 150, 0, 0, 30, 80);
  @include adaptivHeightlg(740, 500);

  @media (max-width: 768px) {
    height: 100%;
    @include adaptivPaddingmd(0, 0, 45, 80, 0, 0, 30, 30);
  }

  &-title {
    @include adaptiv-fontlg(80, 40);
    @include adaptiv-lineHeightlg(80, 40);
    font-family: "Playfair Display", serif;
    font-weight: 400;
    color: #fff;
    @include adaptivMarginlg(0, 0, 0, 50, 0, 0, 0, 25);

    @media (max-width: 768px) {
      @include adaptiv-fontmd(80, 40);
      @include adaptiv-lineHeightmd(80, 40);
    }
  }

  .reviewsSlider {

    display: flex;
    overflow: visible;
    width: 100%;

    
    @media (min-width: 769px) {
      width: 200%;
      transform: translateX(-27%);
    }
    @media (min-width: 1024px) {
      width: 150%;
      transform: translateX(-25%);
    }

    &__slide {

      @include adaptivPaddinglg(20, 20, 0, 0, 10, 10, 0, 0);


      @media (max-width: 768px) {
        min-height: 100%;
        @include adaptivPaddingmd(30, 30, 30, 30, 20, 20, 20, 20);
      }

      &-content {
        width: 100%;
        display: flex;
        justify-content: center;
        border-radius: 15px;
        // height: 100%;
        @include adaptivHeightlg(385, 290);
        overflow: hidden;

        @media (max-width: 768px) {
          @include adaptivHeightmd(1000, 650);
          flex-direction: column;
          justify-content: flex-start;
          margin: 0;
          border-radius: 0;
        }
      }

      &-img {

        // @include adaptivWidthLg(300, 150);
        @media (min-width: 769px) {
          width: 30%;
        }

        height: 100%;
        object-fit: cover;

        @media (max-width: 768px) {
          max-width: 100%;
          min-height: 25%;
          @include adaptivMarginmd(0, 0, 0, 20, 0, 0, 0, 10);
        }
      }

      &-name {
        width: 65%;
        flex: 1;
        display: flex;
        flex-direction: column;
        @include adaptivPaddinglg(45, 45, 20, 30, 20, 20, 20, 15);
        background: #fff;

        @media (max-width: 768px) {
          width: 100%;
          padding: 0;
          background: transparent;
          justify-content: center;
        }

        &-title {
          @include adaptiv-fontlg(29, 15);
          @include adaptiv-lineHeightlg(35, 20);
          font-family: "Lato", sans-serif;
          font-weight: 700;
          color: #525252;

          @media (max-width: 768px) {
            @include adaptiv-fontmd(29, 13);
            @include adaptiv-lineHeightmd(35, 18);
            color: #fff;
          }
        }

        &-link {
          display: flex;
          flex-wrap: wrap;
          @include adaptiv-fontlg(14, 10);
          @include adaptiv-lineHeightlg(17, 10);
          font-family: "Lato", sans-serif;
          font-weight: 300;
          
          @include adaptivMarginlg(0, 0, 4, 25, 0, 0, 2, 15);

          @media (max-width: 768px) {
            @include adaptiv-fontmd(20, 10);
            @include adaptiv-lineHeightmd(24, 10);
            font-weight: 400;
          }
          a{
            color: #209efa;
            &:first-child{
              margin-right: 10px;
            }
          }
        }

        &-descr {
          @include adaptiv-fontlg(14, 10);
          @include adaptiv-lineHeightlg(17, 12);
          font-family: "Lato", sans-serif;
          font-weight: 400;
          color: #525252;

          @media (max-width: 768px) {
            @include adaptiv-fontmd(20, 12);
            @include adaptiv-lineHeightmd(24, 16);
            color: #fff;
          }
        }
      }
    }

    &__navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      // margin-top: 100px;
      @include adaptivMarginlg(0, 0, 35, 35, 0, 0, 15, 15);

      @media (max-width: 768px) {
        justify-content: flex-end;
      }

      &-next,
      &-prev {
        cursor: pointer;
        position: relative;
        transition: 0.4s;
        @include adaptivWidthLg(80, 50);
        width: 100%;
        @include adaptivHeightlg(80, 50);
        @media (max-width: 768px) {
          @include adaptivWidthmd(100, 50);
          @include adaptivHeightmd(100, 50);
        }
        &::after {
          position: absolute;
          background-size: cover;
          display: block;
          width: 100%;
          height: 100%;
          content: "";
        }
        &:hover{
         filter:brightness(0.7); 
        }
        &:active{
          filter:brightness(0.5); 
        }
      }

      &-prev {
        @media (max-width: 768px) {
          display: none;
        }
        &::after {
          background-image: url("../assets/images/icon/arrow2.png");
        }
      }

      &-next {
        &::after {
          background-image: url("../assets/images/icon/arrow.png");
        }
      }
    }
  }
}
</style>