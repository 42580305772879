<template>
  <div class="presentation">
    <div class="container" v-cloak>
      <div class="presentation-date" v-if="pageData.date">{{ pageData.date }}</div>
      <div class="presentation__content" :class="{ changed: dataChanged }">
        <div class="presentation__content-title">
          {{ pageData.title }}
        </div>
        <div class="presentation__content-line"></div>
        <div class="presentation__content-descr">
          {{ pageData.subtitle_collage }}
        </div>
        <div class="presentation__content-imgContent">
          <div class="presentation__content-imgContent_item" v-for="item in pageData.gallery_collage" :key="item.id">
            <img :src="item.image" :alt="item.alt" />
          </div>
        </div>
        <button @click="toGallery" class="presentation__content-btn">
          ← Вернуться назад
        </button>
      </div>
    </div>
  </div>
  <KapibaraFooter />
</template>


<script>
import KapibaraFooter from "@/components/KapibaraFooter.vue";
import { mapGetters } from "vuex";
export default {
  name: 'PhotoShoots',
  components: {
    KapibaraFooter,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      dataChanged: false,
    };
  },
  methods: {
    goToTop() {
      window.scrollTo(0, 0);
    },
    toGallery() {
      const path = {
        name: 'home',
        hash: '#loveStory'
      }
      if (this.pageData.gallery_type=='wedding') {
        path.hash = '#wedding'
      }
      if (this.pageData.gallery_type=='photo_gallery') {
        path.hash = '#photoGallery'
      }
      if (this.$route.name != 'home') {
        this.$router.push(path)
      }
    }
  },
  computed: {
    ...mapGetters({
      getGalleryContent: "getGalleryContent"
    }),
    pageData() {
      return this.getGalleryContent(this.$route.params.id);
    },
  },
  mounted() {
    this.dataChanged = true;
    window.scrollTo(0, 0);
  },
  watch: {
    pageData() {
      this.dataChanged = false;
      setTimeout(() => {
        this.dataChanged = true;
      }, 0);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";
.presentation {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: #fff;
  position: relative;
  @include adaptivPaddinglg(0, 0, 103, 20, 0, 0, 58, 10);

  @media (max-width: 768px) {
    @include adaptivPaddingmd(0, 0, 100, 40, 0, 0, 70, 20);
  }
  &-date {
    @include adaptiv-fontlg(20, 10);
    @include adaptiv-lineHeightlg(25, 15);
    font-family: "Helvetica-normal";
    color: #5a5a5a;
    text-transform: uppercase;
    @include adaptivMarginlg(0, 0, 45, 50, 0, 0, 20, 50);
    @media (max-width: 768px) {
      @include adaptiv-fontmd(20, 10);
      @include adaptiv-lineHeightmd(25, 15);
      @include adaptivMarginmd(0, 0, 30, 0, 0, 0, 20, 10);
    }
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    transform: translateY(0px);
    &.changed {
      animation: transitionContent 0.5s ease-in;
    }
    &-title {
      @include adaptiv-fontlg(80, 40);
      @include adaptiv-lineHeightlg(80, 40);
      font-family: "Playfair Display", serif;
      font-weight: 400;
      color: #5c001f;
      position: relative;
      @media (max-width: 768px) {
        @include adaptiv-fontmd(60, 25);
        @include adaptiv-lineHeightmd(65, 30);
        @include adaptivMarginmd(0, 0, 75, 0, 0, 0, 20, 0);
      }
    }
    &-line {
      width: 70%;
      height: 1px;
      background: #5c001f;
      @include adaptivMarginlg(0, 0, 25, 25, 0, 0, 13, 13);
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        width: 95%;
        @include adaptivMarginmd(0, 0, 25, 25, 0, 0, 13, 13);
      }
      &::before {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #5c001f;
        display: block;
      }
      &::after {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #5c001f;
        display: block;
        margin-left: auto;
      }
    }
    &-descr {
      @include adaptiv-fontlg(25, 13);
      @include adaptiv-lineHeightlg(25, 13);
      font-family: "Helvetica-normal";
      color: #5a5a5a;
      @media (max-width: 768px) {
        @include adaptiv-fontmd(25, 13);
        @include adaptiv-lineHeightmd(25, 13);
      }
    }
    &-imgContent {
      width: 85%;
      display: grid;
      grid-template-columns: 2fr 1.5fr;
      gap: 20px;
      @include adaptivMarginlg(0, 0, 100, 0, 0, 0, 50, 0);
      @media (max-width: 768px) {
        width: 100%;
        gap: 5px;
        @include adaptivMarginmd(0, 0, 60, 0, 0, 0, 30, 0);
      }
      &_item {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &:nth-child(2n + 1) {
          grid-row-end: span 2;
        }
        &:nth-child(2n + 2) {
          grid-row-end: span 1;
        }
      }
    }
    &-btn {
      @include adaptivWidthLg(336, 200);
      width: 100%;
      background: #701d1c;
      @include adaptivPaddinglg(0, 0, 15, 15, 0, 0, 8, 8);
      @include adaptivMarginlg(0, 0, 45, 45, 0, 0, 25, 25);
      color: #fff;
      font-family: "Helvetica-bold";
      @include adaptiv-fontlg(12, 10);
      @include adaptiv-lineHeightlg(12, 10);
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: #294639;
        color: #fff;
        transition: 0.5s;
      }
      @media (max-width: 768px) {
        @include adaptivWidthmd(300, 200);
        @include adaptivPaddingmd(0, 0, 15, 15, 0, 0, 10, 10);
        @include adaptiv-fontmd(10, 8);
        @include adaptiv-lineHeightmd(10, 8);
        @include adaptivMarginmd(0, 0, 45, 0, 0, 0, 25, 0);
      }
    }
  }
}
@keyframes transitionContent {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>