<template>
  <div class="footer">
    <div class="container">
      <div class="footer__content">
        <div class="footer__content-form">
          <h1 class="footer__content-form-title">Tamara Kapibara</h1>
          <form class="form" @submit.prevent @submit="sendFormData">
            <input
              type="text"
              placeholder="Ваше имя"
              name="modal-name"
              class="form-input"
              required
              autocomplete="off"
              v-model="modalName"
            />
            <input
              type="tel"
              placeholder="Номер телефона"
              name="modal-tel"
              class="form-input"
              required
              v-model="modalPhone"
              @input="inputNumber"
              @focus="focusNumber"
            />
            <button class="form-btn" type="submit" v-if="!sent">
              ОТПРАВИТЬ ЗАЯВКУ НА ФОТОСЕССИЮ
            </button>
            <button class="form-btn accept" type="submit" disabled v-else>
              ЗАЯВКА ОТПРАВЛЕНА!
            </button>
          </form>
        </div>
        <div class="footer__content-social">
          <img
            class="footer__content-social-logo"
            src="../assets/images/icon/logoBlack.png"
            alt=""
          />
          <div class="footer__content-social-icon">
            <a
              class="footer__content-social-icon-img"
              href="https://t.me/s/tamara_kapibara"
              target="blank"
              ><img src="../assets/images/icon/black/tg.png" alt=""
            /></a>
            <a
              class="footer__content-social-icon-img"
              href="https://vk.com/@tamarakapibara-moi-put-intervu-fotografa"
              target="blank"
              ><img src="../assets/images/icon/black/vk.png" alt=""
            /></a>
            <a
              class="footer__content-social-icon-img"
              href="https://instagram.com/tamara_kapibara?igshid=YmMyMTA2M2Y="
              target="blank"
              ><img src="../assets/images/icon/black/insta.png" alt=""
            /></a>
          </div>
          <p class="footer__content-social-descr">
            Я буду рада, если смогу запечатлеть важный момент в вашей жизни.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalName: "",
      modalPhone: "",
      sent: false,
    };
  },
  methods: {
    sendFormData() {
      if (
        this.modalName != "" &&
        this.modalPhone != "" &&
        this.modalPhone.length > 9
      ) {
        const formData = new FormData();
        formData.append("name", this.modalName);
        formData.append("contact", this.modalPhone);
        this.$store.dispatch("sendForm", formData).then(() => {
          this.sent = true;
          this.modalName = "";
          this.modalPhone = "";
          setTimeout(() => {
            this.sent = false;
            this.$emit("close");
          }, 1000);
        });
      }
    },
    inputNumber() {
      if (this.modalPhone.length < 2) this.modalPhone = "+";
      this.modalPhone = this.modalPhone.replace(/[^+|^\d|^\s]/, "");
    },
    focusNumber() {
      if (this.modalPhone == "") this.modalPhone = "+";
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";
.footer {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  background: #fff;
  @include adaptivPaddinglg(0, 0, 100, 50, 0, 0, 50, 20);
  @media (max-width: 768px) {
    @include adaptivPaddingmd(0, 0, 50, 50, 0, 0, 20, 20);
  }
  &__content {
    width: 100%;
    @include adaptivMarginlg(0, 0, 150, 0, 0, 0, 80, 0);
    @media (max-width: 768px) {
      @include adaptivMarginmd(0, 0, 60, 0, 0, 0, 30, 0);
    }
    &-form {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px solid #181818;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
      &-title {
        @include adaptiv-fontlg(36, 25);
        @include adaptiv-lineHeightlg(36, 28);
        font-family: "Playfair Display", serif;
        color: #181818;
        @include adaptivMarginlg(0, 0, 0, 20, 0, 0, 0, 10);
        @media (max-width: 768px) {
          @include adaptiv-fontmd(63, 25);
          @include adaptiv-lineHeightmd(98, 28);
        }
      }
      .form {
        width: 70%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @include adaptivMarginlg(0, 0, 0, 20, 0, 0, 0, 10);
        @media (max-width: 768px) {
          width: 100%;
          flex-direction: column;
          align-items: center;
          @include adaptivMarginmd(0, 0, 0, 20, 0, 0, 0, 10);
        }
        &-input {
          @include adaptivWidthLg(350, 200);
          width: 100%;
          border-bottom: 1px solid #cccccc;
          color: #343434;
          font-weight: 600;
          font-family: "Inter", sans-serif;
          @include adaptiv-fontlg(12, 10);
          @include adaptiv-lineHeightlg(14, 12);
          border-radius: 0px;
          &::placeholder {
            @include adaptiv-fontlg(10, 8);
            @include adaptiv-lineHeightlg(10, 8);
            font-weight: 400;
            color: #cccccc;
            @media (max-width: 768px) {
              @include adaptiv-fontmd(10, 8);
              @include adaptiv-lineHeightmd(10, 8);
            }
          }
          @media (max-width: 768px) {
            max-width: 100%;
            @include adaptivHeightmd(80, 40);
            @include adaptiv-fontmd(12, 10);
            @include adaptiv-lineHeightmd(14, 12);
          }
        }
        &-btn {
          @include adaptivWidthLg(350, 200);
          width: 100%;
          background: #000;
          @include adaptivPaddinglg(0, 0, 10, 10, 0, 0, 7, 7);
          color: #fff;
          font-family: "Helvetica-bold";
          @include adaptiv-fontlg(10, 8);
          @include adaptiv-lineHeightlg(10, 8);
          text-transform: uppercase;
          transition: 0.5s;
          &:hover {
            background: #701d1c;
            color: #fff;
            transition: 0.5s;
          }
          @media (max-width: 768px) {
            @include adaptiv-fontmd(13, 10);
            @include adaptiv-lineHeightmd(13, 10);
            @include adaptivPaddingmd(0, 0, 20, 20, 0, 0, 10, 10);
            @include adaptivMarginmd(0, 0, 50, 50, 0, 0, 30, 30);
            max-width: 100%;
          }
        }
        .accept {
          background: #294639;
        }
      }
    }
    &-social {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include adaptivMarginlg(0, 0, 50, 0, 0, 0, 30, 0);
      &-logo {
        @include adaptivWidthLg(50, 30);
        @include adaptivMarginlg(0, 0, 0, 30, 0, 0, 0, 20);
        @media (max-width: 768px) {
          @include adaptivWidthmd(50, 30);
        }
      }
      &-icon {
        width: 12%;
        display: flex;
        justify-content: space-around;
        @media (max-width: 768px) {
          margin-top: auto;
          width: 65%;
          justify-content: space-around;
          align-items: center;
        }
        &-img {
          @include adaptivWidthLg(50, 30);
          width: 100%;
          @include adaptivHeightlg(50, 30);
          border-radius: 50%;
          border: 1px solid rgb(0 0 0 / 12%);
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
          @media (max-width: 768px) {
            @include adaptivWidthmd(60, 50);
            @include adaptivHeightmd(60, 50);
            margin: 0;
          }
          img {
            @include adaptivWidthLg(15, 10);
            width: 100%;
            @media (max-width: 768px) {
              @include adaptivWidthmd(25, 15);
            }
          }
          &:hover {
            border: 1px solid #181818;
            transition: 0.5s;
          }
        }
      }
      &-descr {
        @include adaptiv-fontlg(16, 12);
        @include adaptiv-lineHeightlg(26, 23);
        font-family: "Helvetica-roman";
        color: #656565;
        @include adaptivMarginlg(0, 0, 30, 0, 0, 0, 20, 0);
        @media (max-width: 768px) {
          width: 85%;
          @include adaptiv-fontmd(16, 12);
          @include adaptiv-lineHeightmd(20, 20);
          text-align: center;
        }
      }
    }
  }
}
</style>