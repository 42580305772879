<template>
  <div class="slider">
    <swiper :slidesPerView="1" :breakpoints="{
      '768': {
        slidesPerView: 3,
      },
      '1024': {
        slidesPerView: 4,
      },
      '1920': {
        slidesPerView: 5,
      },
    }" :loop="true" :loopFillGroupWithBlank="true" :speed="4000" :autoplay="{
  enabled: true,
  delay: 1,
  disableOnInteraction: false,
}" :modules="modules" class="mySlider">
      <swiper-slide class="mySlider__slide" v-for="item in getSlider" :key="item.id">
        <img class="mySlider__slide-img" :src="item.image" :alt="item.alt" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import { Autoplay } from "swiper";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
  computed: {
    ...mapGetters({
      getSlider: "getSlider"
    })
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";

.slider {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;


  .mySlider {
    width: 100%;
    overflow: hidden;

    &__slide {
      width: 100%;
      @include adaptivHeightlg(550, 400);
      @media (max-width: 768px) {
        @include adaptivHeightmd(900, 500);
      }

      &-img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: 0.5s;

        &:hover {
          transform: scale(1.05);
          transition: 0.5s;

          @media (max-width: 768px) {
            transform: none;
            transition: none;
          }
        }

        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }

    &-slide {
      overflow: hidden;
    }

    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }
}
</style>