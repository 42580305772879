import { createRouter, createWebHistory} from "vue-router";
import Home from "../views/Home.vue";
import Photoshoots from "../views/Photoshoots.vue";
import Page404 from "../views/Page404.vue";
import store from "@/store"

async function existGalerry(to) {
  const exists = await store.dispatch('existsGallery', to.params.id)
  if (!exists) {

    return {
      name: 'error',
      params: { pathMatch: to.path.substring(1).split('/') },
      query: to.query,
      hash: to.hash,
    }
  }

}


const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: `/photoshoots/:id`,
    name: "photoshoots",
    component: Photoshoots,
    beforeEnter: [existGalerry],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "error",
    component: Page404,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
