<template>
  <KapibaraMain></KapibaraMain>
  <KapibaraSlider></KapibaraSlider>
  <KapibaraContent></KapibaraContent>
  <KapibaraLoveStory></KapibaraLoveStory>
  <KapibaraPhotoGallery></KapibaraPhotoGallery>
  <KapibaraAbout></KapibaraAbout>
  <KapibaraTeaching></KapibaraTeaching>
  <KapibaraPrice></KapibaraPrice>
  <KapibaraReviews></KapibaraReviews>
  <KapibaraFooter></KapibaraFooter>
</template>

<script>
import KapibaraMain from "../components/KapibaraMain.vue";
import KapibaraSlider from "../components/KapibaraSlider.vue";
import KapibaraContent from "../components/KapibaraContent.vue";
import KapibaraLoveStory from "../components/KapibaraLoveStory.vue";
import KapibaraPhotoGallery from "@/components/KapibaraPhotoGallery.vue";
import KapibaraAbout from "../components/KapibaraAbout.vue";
import KapibaraTeaching from "../components/KapibaraTeaching.vue";
import KapibaraPrice from "../components/KapibaraPrice.vue";
import KapibaraReviews from "../components/KapibaraReviews.vue";
import KapibaraFooter from "../components/KapibaraFooter.vue";
import { mapActions } from "vuex";
export default {
  name: 'HomePage',
  components: {
    KapibaraMain,
    KapibaraSlider,
    KapibaraContent,
    KapibaraLoveStory,
    KapibaraPhotoGallery,
    KapibaraAbout,
    KapibaraTeaching,
    KapibaraPrice,
    KapibaraReviews,
    KapibaraFooter
  },
  methods:{
    ...mapActions({
      getContent: 'getContent'
    })
  },
  created() {

    this.getContent()

  }
};
</script>
