<template>
  <KapibaraNavigation />
  <router-view></router-view>
</template>


<script>
import KapibaraNavigation from "@/components/KapibaraNavigation.vue";


export default {
  components: {
    KapibaraNavigation,
  },
  methods: {
  },
  mounted(){

  }
};
</script>


<style lang="scss">
@import "@/assets/scss/grid/grid";

</style>


