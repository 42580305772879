<template>
  <div class="main">
    <h1 class="main-title">TAMARA <br /><span>KAPIBARA</span></h1>
    <swiper 
    :effect="'fade'" 
    :navigation="false" 
    :loop="true" 
    :speed="2500" 
    :autoplay="{
      enabled: true,
      delay: 1,
      disableOnInteraction: false,
    }" :modules="modules" class="mySwiper">
      <swiper-slide class="mySwiper__slide" v-for="(item, index) in getHeaderSlider" :key="index">
        <img class="mySwiper__slide-img" :src="item.image" :alt="item.alt" />
        <img class="mySwiper__slide-imgMob" :src="item.mob_image" :alt="item.mob_alt"/>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectFade, Navigation, Autoplay } from "swiper";
import { mapGetters } from "vuex";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [EffectFade, Navigation, Autoplay],
    };
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      getHeaderSlider: 'getHeaderSlider'
    })
  },
  mounted() {
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";
.main {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  &-title {
    @include adaptiv-fontlg(88, 50);
    @include adaptiv-lineHeightlg(88, 50);
    position: absolute;
    z-index: 2;
    font-family: "Playfair Display", serif;
    color: #fff;
    text-align: center;
    right: 0;
    left: 0;
    top: 30%;
    user-select: none;
    span {
      @include adaptiv-fontlg(323, 150);
      @include adaptiv-lineHeightlg(220, 120);
    }
    @media (max-width: 768px) {
      @include adaptiv-fontlg(50, 30);
      @include adaptiv-lineHeightlg(50, 30);
      margin-top: 50px;
      span {
        @include adaptiv-fontmd(120, 60);
        @include adaptiv-lineHeightmd(120, 60);
      }
    }
  }
  .mySwiper {
    width: 100%;
    @include adaptivHeightlg(950, 550);
    @media (max-width: 768px) {
      @include adaptivHeightmd(1000, 580);
    }
    &__slide {
      width: 100%;
      height: 100%;
      &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(0.9);
        @media (max-width: 768px) {
          display: none;
        }
      }
      &-imgMob {
        display: none;
        @media (max-width: 768px) {
          display: block;
          filter: brightness(0.9);
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }
    .swiper-button-prev {
      display: none;
    }
    .swiper-button-next {
      top: 80%;
      right: 30%;
      z-index: 10;
      @include adaptivWidthLg(100, 50);
      width: 100%;
      @include adaptivHeightlg(100, 50);
      @media (max-width: 768px) {
        @include adaptivWidthmd(100, 50);
        @include adaptivHeightmd(100, 50);
        right: 10%;
      }
      &::after {
        background-image: url("../assets/images/icon/arrow.png");
        background-size: cover;
        display: inline-block;
        width: 100%;
        height: 100%;
        content: "";
      }
    }
  }
}
</style>

