<template>
    <div class="error">
      <div class="container">
        <div class="error__content">
          <h3 class="error__content-404">404</h3>
          <h3 class="error__content-title">страница не найдена</h3>
          <h3 class="error__content-descr">
            вернуться на <router-link class="home" :to="{ name: 'home' }">главную</router-link>
          </h3>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        title: {
          ru: 'страница не найдена',
          uz: `sahifa topilmadi`
        }
      }
    },
    computed: {
      activeLanguage() {
        return this.$store.getters.getLanguage
      }
    },
   mounted() {
  }
  }
  </script>
  
  <style lang="scss">
  @import '@/assets/scss/grid/grid';
  .error {
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    background: #701d1c;
    display: flex;
    justify-content: center;
    align-items: center;
    @include adaptivPaddinglg(0, 0, 50, 100, 0, 0, 25, 70);
    user-select: none;
    height: 100vh;
    @media (max-width: 768px) {
      @include adaptivPaddingmd(0, 0, 55, 100, 0, 0, 35, 50);
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      max-width: 560px;
      &-404 {
        font-size: 264px;
        line-height: 319px;
        font-family: "Playfair Display", serif;
        font-weight: 700;
        color: #fff;
        @media(max-width: 768px){
          @include adaptiv-fontmd(200, 140);
          @include adaptiv-lineHeightmd(220, 160);
        }
      }
      &-title {
        font-size: 48px;
        line-height: 58px;
        font-family: "Playfair Display", serif;
        font-weight: 400;
        color: #fff;
        @include adaptivMarginlg(0, 0, 20, 0, 0, 0, 10, 0);
        @media(max-width: 768px){
          @include adaptiv-fontmd(48, 25);
          @include adaptiv-lineHeightmd(53, 30);
        }
      }
      &-descr, .home{
        font-size: 32px;
        line-height: 39px;
        font-family: "Helvetica-normal";
        color: #fff;
        @include adaptivMarginlg(0, 0, 40, 0, 0, 0, 20, 0);
        @media(max-width: 768px){
          @include adaptiv-fontmd(48, 25);
          @include adaptiv-lineHeightmd(53, 30);
        }
      }
      .home{
        text-decoration: underline;
      }
    }
  }
  </style>