import Api from "@/api"
export default {
  state() { },
  getters: {},
  mutations: {},
  actions: {
    async sendForm(context, formData) {
      let response = await Api.sendForm(formData)
      return response

    }
  },
};
