<template>
  <div class="navigation">
    <div class="container">
      <div class="navigation__menu">
        <router-link :to="{ name: 'home' }" @click="goToTop">
          <img class="logo" src="../assets/images/icon/logo.png" />
        </router-link>
        <div class="navigation__menu-box" @click="show = !show">
          <p class="navigation__menu-box-descr">Menu</p>
          <button class="navigation__menu-box-btn">
            <div class="navigation__menu-box-btn_line1"></div>
            <div class="navigation__menu-box-btn_line2"></div>
          </button>
        </div>
      </div>
    </div>
    <div class="menu">
      <div class="menu-shadow" :class="{ active: show }" @click="show = !show"></div>
      <Transition name="slide-fade">
        <div class="menu__content" v-if="show">
          <button class="menu__content-btnClose" @click="show = !show">
            <img src="../assets/images/icon/close.png" alt="" />
          </button>
          <button
            class="menu__content-link"
            @click="scrollToSection('#loveStory')"
          >
            свадьбы
          </button>
          <button
            class="menu__content-link"
            @click="scrollToSection('#gallery')"
          >
            съёмки
          </button>
          <button class="menu__content-link" @click="scrollToSection('#about')">
            обо мне
          </button>
          <button
            class="menu__content-link"
            @click="scrollToSection('#teaching')"
          >
            обучаю
          </button>
          <button class="menu__content-link" @click="scrollToSection('#price')">
            прайс
          </button>
          <button class="menu__content-link" @click="scrollToSection('#reviews')">
            отзывы
          </button>
          <div class="menu__content-icon">
            <a class="menu__content-icon-img" href="https://t.me/s/tamara_kapibara" target="blank"
              ><img src="../assets/images/icon/white/tg.png" alt=""
            /></a>
            <a class="menu__content-icon-img" href="https://vk.com/@tamarakapibara-moi-put-intervu-fotografa" target="blank"
              ><img src="../assets/images/icon/white/vk.png" alt=""
            /></a>
            <a class="menu__content-icon-img" href="https://instagram.com/tamara_kapibara?igshid=YmMyMTA2M2Y=" target="blank"
              ><img src="../assets/images/icon/white/insta.png" alt=""
            /></a>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    scrollTo(sectionId) {
      if (this.show) {
        this.show = false;
      }
      const section = document.querySelector(sectionId);
      const scrolDistance = section.offsetTop - 0;
      let distance = window.scrollY;
      const scroll = () => {
        if (scrolDistance > distance + 0.5 || scrolDistance < distance - 0.5) {
          distance -= (distance - scrolDistance) / 15;
          window.scrollTo(0, distance);
          this.timer = setTimeout(() => {
            scroll();
          }, 5);
        } else {
          window.scrollTo(0, scrolDistance);
          stop();
        }
      };
      const stop = () => {
        clearTimeout(this.timer);
        window.removeEventListener("wheel", stop);
      };
      stop();
      window.addEventListener("wheel", stop);
      scroll();
    },
    scrollToSection(sectionId) {
      if (this.$route.name != "home") {
        this.$router.push("/").then(() => {
          setTimeout(() => {
            this.scrollTo(sectionId);
          }, 300);
        });
      } else {
        this.scrollTo(sectionId);
      }
    },
    goToTop() {
      window.scrollTo(0, 0);
    },
    stopScroll(){
      if(!this.show){
        document.body.classList.add(".scroll-hidden")
      }
    }
  },
  computed: {
    pageData() {
      return this.$store.getters.getPageData(this.$route.params.id);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(500px);
  opacity: 0;
}

.navigation {
  width: 100%;
  background: transparent;
  user-select: none;
  position: fixed;
  z-index: 999;
  background: rgba(0, 0, 0, 0.103);
  @include adaptivPaddinglg(0, 0, 20, 20, 0, 0, 10, 10);
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
  }
  &__menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      @include adaptivHeightmd(80, 50);
    }
    .logo {
      display: block;
      @include adaptivWidthLg(50, 30);
      @media (max-width: 768px) {
        z-index: 999;
        display: block;
        @include adaptivWidthmd(50, 30);
      }
    }
    &-box {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
      @media (max-width: 768px) {
        height: 100%;
        align-items: center;
        position: absolute;
        z-index: 10;
        right: 5%;
      }
      &:hover {
        .navigation__menu-box-btn_line1 {
          width: 80%;
          transition: 0.5s;
        }
        .navigation__menu-box-btn_line2 {
          width: 100%;
          transition: 0.5s;
        }
      }
      &-descr {
        color: #fff;
        @include adaptiv-fontlg(25, 18);
        font-family: "Playfair Display", serif;
        text-transform: uppercase;
        margin-right: 30px;
        @media (max-width: 768px) {
          @include adaptiv-fontmd(22, 15);
        }
      }
      &-btn {
        @include adaptivWidthLg(60, 40);
        width: 100%;
        background: none;
        position: relative;
        display: flex;
        justify-content: flex-end;
        @media (max-width: 768px) {
          width: 40px;
          height: 20px;
        }
        &_line1 {
          width: 100%;
          height: 4px;
          background: #fff;
          position: absolute;
          bottom: 0;
          border-radius: 10px;
          transition: 0.5s;
        }
        &_line2 {
          width: 70%;
          height: 4px;
          background: #fff;
          position: absolute;
          top: 0;
          border-radius: 10px;
          transition: 0.5s;
        }
      }
    }
  }
  .menu {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      max-width: 100%;
    }
    &-shadow {
      display: none;
      &.active {
        display: block;
        width: 100%;
        min-height: 100vh;
        position: absolute;
        top: -80px;
        @include adaptivMarginlg(0, 0, 100, 0, 0, 0, 90, 0);
        left: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.103);
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
    &__content {
      @include adaptivWidthLg(600, 400);
      width: 100%;
      @include adaptivHeightlg(600, 400);
      background: #294639;
      position: absolute;
      top: 50px;
      z-index: 10;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      @include adaptivPaddinglg(0, 0, 100, 40, 0, 0, 80, 55);
      @media (max-width: 768px) {
        max-width: 100%;
        position: absolute;
        @include adaptivMarginmd(0, 0, -160, 0, 0, 0, -130, 0);
        height: 150vh;
        @include adaptivPaddingmd(0, 0, 200, 90, 0, 0, 150, 45);
      }
      &-btnClose {
        background: none;
        margin-left: auto;
        position: absolute;
        right: 5%;
        top: 5%;
        img {
          @include adaptivWidthLg(40, 20);
          width: 100%;
          @include adaptivHeightlg(40, 20);
          @media (max-width: 768px) {
            @include adaptivWidthmd(40, 22);
            width: 100%;
            @include adaptivHeightmd(40, 22);
            height: 100%;
          }
        }
      }
      &-link {
        background: transparent;
        // @include adaptivHeightlg(90, 45);
        color: #fff;
        font-family: "Helvetica-normal";
        @include adaptiv-fontlg(35, 22);
        @include adaptiv-lineHeightlg(55, 35);
        transition: 0.5s;
        transform: translateX(0px);
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @include adaptivPaddinglg(90, 40, 0, 0, 45, 20, 0, 0);
        &:hover {
          transition: 0.5s;
          transform: translateX(10px);
          font-family: "Helvetica-roman";
          &::after {
            content: "";
            width: 150px;
            height: 1px;
            background: #fff;
            position: absolute;
            display: block;
            margin-top: 50px;
            @media (max-width: 768px) {
              display: none;
            }
          }
          @media (max-width: 768px) {
            background: #701d1c;
            transform: none;
          }
        }
        @media (max-width: 768px) {
          @include adaptivHeightmd(120, 65);
          @include adaptiv-fontmd(45, 25);
          @include adaptiv-lineHeightmd(54, 34);
          @include adaptivPaddingmd(70, 0, 0, 0, 35, 0, 0, 0);
        }
      }
      &-icon {
        width: 100%;
        display: flex;
        @include adaptivMarginlg(0, 0, 60, 0, 0, 0, 30, 0);
        @include adaptivPaddinglg(90, 40, 0, 0, 45, 20, 0, 0);
        @media (max-width: 768px) {
          @include adaptivMarginmd(0, 0, 80, 0, 0, 0, 40, 0);
          @include adaptivPaddingmd(70, 0, 0, 0, 35, 0, 0, 0);
        }
        &-img {
          @include adaptivWidthLg(84, 42);
            width: 100%;
            @include adaptivHeightlg(84, 42);
          border-radius: 50%;
          border: 1px solid rgb(255 255 255 / 12%);
          display: flex;
          align-items: center;
          justify-content: center;
          @include adaptivMarginlg(0, 30, 0, 0, 0, 15, 0, 0);
          transition: 0.5s;
          @media (max-width: 768px) {
            @include adaptivWidthmd(100, 60);
            @include adaptivHeightmd(100, 60);
            @include adaptivMarginmd(0, 30, 0, 0, 0, 15, 0, 0);
          }
          img {
            @include adaptivWidthLg(15, 12);
            @media (max-width: 768px) {
              @include adaptivWidthmd(22, 14);
            }
          }
          &:hover {
            border: 1px solid #fff;
            transition: 0.5s;
          }
        }
      }
    }
  }
}
</style>