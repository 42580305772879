<template>
  <div class="content">
    <div class="container">
      <div class="content__block">
        <div class="content__block-items">
          <div class="content__block-items-box" v-for="item in getCollage" :key="item.id">
            <img class="shoot" src="../assets/images/icon/shoot.png" alt="" />
            <h3 class="content__block-items-box-title" v-html="item.title"></h3>
            <img :src="item.image" :alt="item.alt" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getCollage: "getCollage"
    })
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";

.content {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  background: #fff;
  @include adaptivPaddinglg(0, 0, 20, 20, 0, 0, 10, 10);

  &__block {
    width: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &-items {
      width: 80%;
      display: grid;
      grid-template-columns: 2fr 1.5fr;
      @include adaptivGaplg(40, 20);
      overflow: hidden;

      @media(max-width: 768px) {
        width: 100%;
        @include adaptivGapmd(70, 30);
        grid-template-columns: 1fr;
      }

      &-box {
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          filter: none;
        }

        &:nth-child(2n + 1) {
          grid-row-end: span 2;
        }

        &:nth-child(2n + 2) {
          grid-row-end: span 1;
        }

        &:hover {
          .shoot {
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            @include adaptivWidthLg(150, 90);
            @include adaptivHeightlg(100, 60);
            z-index: 1;
            transition: 0.5s;
            transform: translate(-50%, -38%);
            top: 50%;
            left: 50%;

            @media (max-width: 768px) {
              @include adaptivWidthmd(150, 90);
              @include adaptivHeightmd(100, 60);
              top: 50%;
              left: 50%;
              transform: translate(-50%, -38%);
            }
          }

          img {
            animation: shoot 1s ease-in-out;
          }
        }

        .shoot {
          opacity: 0;
          display: none;
        }

        &-title {
          width: 100%;
          color: #101010;
          font-family: "Helvetica-normal";
          @include adaptiv-fontlg(20, 11);
          @include adaptiv-lineHeightlg(40, 20);
          text-transform: uppercase;
          text-align: left;

          @media (max-width: 768px) {
            @include adaptiv-fontmd(30, 13);
            @include adaptiv-lineHeightmd(63, 25);
          }

          span {
            color: #701d1c;
          }
        }
      }
    }
  }

  @keyframes shoot {
    0% {
      filter: blur(0px) brightness(1);
    }

    50% {
      filter: blur(1px) brightness(0.8);
    }

    100% {
      filter: blur(0px) brightness(1);
    }
  }
}
</style>