import { createStore } from 'vuex'
import content from "./modules/content";
import form from "./modules/form";
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    content,
    form
  }
})
